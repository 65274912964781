









































































































































































































































































































































.el-tooltip__popper {
    max-width: 50% !important;
}
